import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import 'moment/locale/uk';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { isAdmin } from '../../utils';
import Avatar from '@material-ui/core/Avatar';
import './ribon.css';

const styles = {
  card: {
    maxWidth: 350,
    margin: 10,
    minWidth: 350,
    flex: 1,
    position: 'relative'
  },
  media: {
    objectFit: 'cover'
  }
};

const Overlay = styled.h1`
  text-shadow: 0px 0px 8px rgb(0, 0, 0);
  position: absolute;
  color: white;
  font-size: 34px;
  text-align: center;
  width: 100%;
  div:first-child {
    opacity: 0.7;
  }
`;

class ImgMediaCard extends Component {
  state = { open: false };

  prompt = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleSave = () => {
    this.setState({ open: false });
    this.props.editTrain({
      freeBikes: this.state.freeBikes,
      //time: this.state.time,
      id: this.props.train.id
    });
  };

  render() {
    const {
      classes,
      currentUser,
      goToEvent,
      event,
      defaultImage,
      inTeam,
      registerToEvent
    } = this.props;
    const {
      participants,
      registredParticipants = [],
      start,
      title,
      description,
      image,
      url,
      license,
      priority
    } = event;
    const admin = isAdmin({ id: currentUser });
    const eventMarkedToVisit = participants.some(user => currentUser === user.id);
    const userHasSlot = registredParticipants.some(user => currentUser === user);
    const showButton = !!currentUser && !userHasSlot;

    return (
      <Card className={classes.card}>
        <CardActionArea>
          <Overlay />
          <CardMedia
            component="img"
            alt="Зображення"
            className={classes.media}
            height="160"
            image={image}
            title="Тренування"
          />
          <CardContent style={{ paddingBottom: '46px' }}>
            <a href={url} style={{ textDecoration: 'none' }}>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
            </a>
            <Typography gutterBottom variant="p" component="i">
              {description} {license && 'потрібна ліцензія'}
            </Typography>
            <hr />
            <Typography component="ol">
              {participants.map(({ username, photoURL, id }, i) => {
                const src = photoURL.includes('graph') ? photoURL + '?width=25' : photoURL;
                return (
                  <li
                    key={id}
                    onClick={() => {
                      window.location = `/rating?user=${id}`;
                    }}
                    style={{ fontSize: 12 }}
                  >
                    {photoURL && (
                      <Avatar
                        src={src}
                        style={{
                          verticalAlign: 'middle',
                          width: 25,
                          height: 25,
                          display: 'inline-table',
                          marginRight: 2,
                          color: 'white'
                        }}
                      />
                    )}
                    {username + ' '}
                    {registredParticipants.includes(id) && (
                      <span
                        style={{
                          padding: '2px 5px',
                          backgroundColor: 'black',
                          color: 'white',
                          borderRadius: 5
                        }}
                      >
                        є слот
                      </span>
                    )}
                  </li>
                );
              })}
            </Typography>

            {priority === 'a' && inTeam && (
              <div className="corner-ribbon top-right red">Дуже важливо</div>
            )}
            {priority === 'b' && inTeam && (
              <div className="corner-ribbon top-right green">Важливо</div>
            )}
            {priority === 'с' && inTeam && (
              <div className="corner-ribbon top-right yellow">Котимося</div>
            )}
            <br />
          </CardContent>
        </CardActionArea>
        <CardActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            bottom: '0',
            position: 'absolute',
            width: '100%'
          }}
        >
          {showButton ? (
            !userHasSlot && eventMarkedToVisit ? (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  registerToEvent(event, currentUser);
                }}
              >
                {userHasSlot ? 'Скасувати' : 'Куплено слот'}
              </Button>
            ) : (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  goToEvent(event, currentUser);
                }}
              >
                {eventMarkedToVisit ? 'Скасувати' : 'Планую'}
              </Button>
            )
          ) : (
            <span />
          )}

          <Typography component="span" style={{ fontSize: '10px' }}>
            {moment(start).format('DD MMMM')}
            {admin && (
              <div>
                <IconButton aria-label="edit" onClick={this.prompt}>
                  <EditIcon style={{ fontSize: 18 }} />
                </IconButton>
              </div>
            )}
          </Typography>
        </CardActions>
      </Card>
    );
  }
}

ImgMediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ImgMediaCard);
