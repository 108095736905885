import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import 'moment/locale/uk';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

class AddEventDialog extends Component {
  state = {
    event: {
      date: new Date(),
      image: '',
      license: false,
      participants: [],
      priority: '',
      description: '',
      title: '',
      url: ''
    }
  };

  setEventProp = keyValue => {
    this.setState(state => ({ event: { ...state.event, ...keyValue } }));
  };

  render() {
    const { createEvent, show, close } = this.props;
    return (
      <div>
        <Dialog open={show} onClose={close} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Створити подію</DialogTitle>
          <DialogContent>
            <TextField
              value={this.state.event.title}
              autoFocus
              margin="dense"
              label="Назва"
              type="text"
              fullWidth
              onChange={e => {
                this.setEventProp({ title: e.target.value });
              }}
            />
            <TextField
              value={this.state.event.date}
              autoFocus
              defaultValue={new Date()}
              margin="dense"
              label="Дата"
              type="date"
              fullWidth
              onChange={e => {
                console.log();
                this.setEventProp({ date: e.target.value });
              }}
            />
            <TextField
              value={this.state.event.image}
              margin="dense"
              label="Посилання на лого (зображення) заходу"
              type="text"
              fullWidth
              onChange={e => this.setEventProp({ image: e.target.value })}
            />
            <TextField
              value={this.state.event.url}
              margin="dense"
              label="Посилання на подію"
              type="text"
              fullWidth
              onChange={e => this.setEventProp({ url: e.target.value })}
            />
            <div>
              Пріоритет для команди{' '}
              <Select
                value={this.state.event.priority}
                onChange={e => this.setEventProp({ priority: e.target.value })}
                displayEmpty
                name="age"
              >
                <MenuItem value="" />
                <MenuItem value="c">Низький</MenuItem>
                <MenuItem value="b">Середній</MenuItem>
                <MenuItem value="a">Високий</MenuItem>
              </Select>
            </div>

            <TextField
              value={this.state.event.description}
              margin="dense"
              label="Ваш опис гонки"
              multiline={true}
              type="text"
              fullWidth
              onChange={e => this.setEventProp({ description: e.target.value })}
            />

            <div>
              Потрібна ліцензія
              <Checkbox
                checked={this.state.event.license}
                onChange={e => this.setEventProp({ license: e.target.checked })}
                value="license"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                const event = {
                  ...this.state.event,
                  date: moment(this.state.event.date, 'YYYY-MM-DD').toDate()
                };
                createEvent(event);
                close();
              }}
              color="primary"
            >
              Ок
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddEventDialog;
