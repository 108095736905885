import React, { Component, Fragment } from 'react';
import { withFirebase } from '../components/Firebase/FirebaseContext';
import { withEvents } from '../BuisenessLogic/EventsContext';
import Layout from '../components/layout';
import { Background } from './trainings';
import Card from '../components/Card/eventCard';
import styled from 'styled-components';
import AddEventDialog from '../components/Dialog/AddEventDialog';
import { FaCalendar } from 'react-icons/fa';
import { FaTable } from 'react-icons/fa/index';
import BigCalendar from 'react-big-calendar';
import '../components/Calendar/calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
const localizer = BigCalendar.momentLocalizer(moment);

const AddEventButton = styled.div`
  width: 100%;
  text-align: center;
  bottom: 0;
  cursor: pointer;
  color: #c71309;
  a {
    text-decoration: none !important;
  }
`;
const Toolbar = styled.div`
  display: flex;
  justify-content: space-evenly;
  div {
    width: 30%;
    text-align: center;
    cursor: pointer;
    svg {
      padding-left: 2px;
    }
  }
`;

class Events extends Component {
  state = {
    inTeam: false,
    events: [],
    showDialog: false,
    calendar: false
  };
  constructor(props) {
    super(props);
    this.props.firebase.base.listenToCollection('events', {
      context: this,
      state: 'bar',
      then() {
        this.props.events.getEvents().then(events => this.setState({ events }));
      }
    });
  }

  componentDidMount() {
    if (this.props.events) {
      this.props.events.getEvents().then(events => this.setState({ events }));
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.init && this.props.firebase.auth.O) {
      this.init = true;
      this.props.firebase
        .getUser(this.props.firebase.auth.O)
        .then(user => this.setState({ inTeam: user.inTeam }));
    }
  }

  close = () => {
    this.setState({ showDialog: false });
  };
  render() {
    const currentUser = this.props.firebase?.auth?.currentUser?.uid;
    return (
      <Fragment>
        <Background color={'#ccc'}>
          <Toolbar>
            <div
              onClick={() => {
                this.setState({ calendar: true });
              }}
            >
              Календар
              <FaCalendar />
            </div>
            <div
              onClick={() => {
                this.setState({ calendar: false });
              }}
            >
              Таблиця
              <FaTable />
            </div>
          </Toolbar>
          <div className={'flex-container'}>
            {this.state.calendar ? (
              <BigCalendar
                localizer={localizer}
                events={this.state.events.map(e => {
                  return {
                    ...e,
                    start: e.start,
                    end: moment(e.start).add(1, 'day'),
                    allDay: true
                  };
                })}
                defaultView={'month'}
              />
            ) : (
              this.state.events.map(event => (
                <Card
                  event={event}
                  currentUser={currentUser}
                  goToEvent={this.props.events.goToEvent}
                  registerToEvent={this.props.events.registerToEvent}
                  key={event.id}
                  allDayAccessor={true}
                  inTeam={this.state.inTeam}
                />
              ))
            )}
          </div>
        </Background>
        {this.state.inTeam && (
          <AddEventButton
            onClick={() => {
              this.setState({ showDialog: true });
            }}
          >
            <button>Додати подію</button>
          </AddEventButton>
        )}

        <AddEventDialog
          show={this.state.showDialog}
          createEvent={this.props.events.createEvent}
          close={this.close}
        />
      </Fragment>
    );
  }
}

const LandingPageWithFirebase = withFirebase(withEvents(Events));

export default () => (
  <Layout>
    <LandingPageWithFirebase />
  </Layout>
);
